"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** Checks we need to do before leave */
var checks = [];
/** Only alert in browsers */
if (typeof window !== 'undefined') {
    var getMessageIfAny_1 = function () {
        var message = '';
        for (var _i = 0, checks_1 = checks; _i < checks_1.length; _i++) {
            var check = checks_1[_i];
            message = check();
            if (message)
                break;
        }
        return message;
    };
    /** Browser close and reload */
    window.addEventListener('beforeunload', function (e) {
        var message = getMessageIfAny_1();
        if (!message)
            return; // Dont alert
        e.preventDefault(); // Without this XHRs get cancelled
        e.returnValue = message; // Gecko, Trident, Chrome 34+
        return message; // Gecko, WebKit, Chrome <34
    });
}
function alertOnLeave(check) {
    checks.push(check);
    return function () {
        checks = checks.filter(function (c) { return c !== check; });
    };
}
exports.alertOnLeave = alertOnLeave;
